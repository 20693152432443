export const VISIBILITY_TYPE_PUBLIC = 'public'
export const VISIBILITY_TYPE_WITH_LINK = 'with_link'

export default {
  methods: {
    prettyVisibility: function (id) {
      return this.visibilityTypeValues.find(value => value.id === id)?.title ?? id
    }
  },
  computed: {
    visibilityTypeValues () {
      return [
        {
          id: VISIBILITY_TYPE_PUBLIC,
          title: this.$t('eshop.visibility_type.public')
        },
        {
          id: VISIBILITY_TYPE_WITH_LINK,
          title: this.$t('eshop.visibility_type.with_link')
        }
      ]
    }
  }
}
