export default {
  methods: {
    prettyProductSelectOption ({ id, name, priceInEuro }) {
      return `${id} - ${name} (${priceInEuro} EUR)`
    },
    isPublished (product) {
      const now = new Date()
      return new Date(product.publishedSince) < now && now < new Date(product.publishedUntil)
    },
    isActive (product) {
      return product.enabled && this.isPublished(product)
    },
    isNotActive (product) {
      return !this.isActive(product)
    }
  }
}
