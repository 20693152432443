<template>
  <app-module-view>
    <template slot="buttons">
      <button
        v-if="isSaveButtonVisible"
        type="button"
        class="btn btn-success"
        @click="save"
      >
        <i class="fa fa-save "></i> {{ $t('eshopSubscriptionProduct.save') }}
      </button>
    </template>
    <template slot="body">
      <div class="alert alert-danger" role="alert" v-if="error">
        <i class="fa fa-exclamation-triangle"></i> {{ error }}
      </div>
      <div class="row">
        <div
          v-for="topProductSetting in topProductSettings"
          :key="topProductSetting.position"
          class="col-md-4"
        >
          <div class="text-center">
            <h3>{{ prettyPosition(topProductSetting.position) }}</h3>
          </div>
          <div>
            <app-select
              v-model="topProductSetting.product"
              :options="products"
              no-label
              :custom-title="prettyProductSelectOption"
              track-by="id"
              id="subscriptionProduct_aboProduct"
              noEmptyValue
            >
            </app-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <table class="table table-sm">
                <thead>
                <tr>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.id') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.name') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.period') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.price') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.visibility') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.enabled') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.published_since') }}</th>
                  <th scope="col">{{ $t('eshopSubscriptionProduct.published_until') }}</th>
                  <th scope="col">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="product in products"
                  :key="product.id"
                  class="col-md-4"
                  :class="{ 'table-danger': isNotActive(product)}"
                >
                  <td>{{ product.id }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ prettyPeriod(product.period) }}</td>
                  <td>{{ product.priceInEuro }}</td>
                  <td>{{ prettyVisibility(product.visibility) }}</td>
                  <td><i v-if="product.enabled" class="fas fa-check-circle"></i></td>
                  <td>{{ product.publishedSince | prettyDateTime }}</td>
                  <td>{{ product.publishedUntil | prettyDateTime }}</td>
                  <td>
                    <router-link
                      v-if="isEditProductButtonVisible"
                      tag="a"
                      class="btn btn-default btn-sm"
                      :to="{name: 'eshopSubscriptionProduct_edit', params: { id: product.id }}"
                    >
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import PeriodTypeMixin from '../mixins/valueObject/eshop/PeriodTypeMixin'
import VisibilityTypeMixin from '../mixins/valueObject/eshop/VisibilityTypeMixin'
import TopProductPositionTypeMixin from '../mixins/valueObject/eshop/TopProductPositionTypeMixin'
import { mapState } from 'vuex'
import Select from '@/components/form/select/Select'
import NotifyService from '@/services/NotifyService'
import ProductTypeMixin from '@/components/mixins/valueObject/eshop/ProductTypeMixin'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'TopProductSetting',
  mixins: [
    PeriodTypeMixin,
    VisibilityTypeMixin,
    TopProductPositionTypeMixin,
    ProductTypeMixin
  ],
  components: {
    appModuleView: ModuleView,
    appSelect: Select
  },
  data () {
    return {
      topProductSettings: []
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isSaveButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ESHOP_TOP_PRODUCT_PERMISSIONS.saveButton
      return this.hasPermission(permission)
    },
    isEditProductButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ESHOP_PRODUCT_PERMISSIONS.editButton
      return this.hasPermission(permission)
    },
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    },
    error () {
      return this.$store.getters['eshopTopProductSetting/error']
    }
  },
  created () {
    this.$store.dispatch('eshopTopProductSetting/fetchAll')
      .then(() => {
        this.topProductSettings = this.$store.getters['eshopTopProductSetting/allSortedLeftToRight']
      })
    this.$store.dispatch('eshopSubscriptionProduct/fetchAll')
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async save () {
      this.$store.dispatch('eshopTopProductSetting/update', this.topProductSettings)
        .then(() => {
          NotifyService.setSuccessMessage(this.$t('eshopTopProductSetting.message.updated_record'))
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
