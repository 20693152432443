export const PERIOD_TYPE_NONE = 'none'
export const PERIOD_TYPE_WEEK = 'week'
export const PERIOD_TYPE_MONTH = 'month'
export const PERIOD_TYPE_3_MONTHS = '3_months'
export const PERIOD_TYPE_6_MONTHS = '6_months'
export const PERIOD_TYPE_1_YEAR = '1_year'
export const PERIOD_TYPE_2_YEARS = '2_years'
export const PERIOD_TYPE_1_ARTICLE = '1_article'

export default {
  methods: {
    prettyPeriod: function (id) {
      return this.periodTypeValues.find(value => value.id === id)?.title ?? id
    }
  },
  computed: {
    periodTypeValues () {
      return [
        {
          id: PERIOD_TYPE_NONE,
          title: this.$t('eshop.period_type.none')
        },
        {
          id: PERIOD_TYPE_WEEK,
          title: this.$t('eshop.period_type.week')
        },
        {
          id: PERIOD_TYPE_MONTH,
          title: this.$t('eshop.period_type.month')
        },
        {
          id: PERIOD_TYPE_3_MONTHS,
          title: this.$t('eshop.period_type.three_months')
        },
        {
          id: PERIOD_TYPE_6_MONTHS,
          title: this.$t('eshop.period_type.six_months')
        },
        {
          id: PERIOD_TYPE_1_YEAR,
          title: this.$t('eshop.period_type.one_year')
        },
        {
          id: PERIOD_TYPE_2_YEARS,
          title: this.$t('eshop.period_type.two_years')
        },
        {
          id: PERIOD_TYPE_1_ARTICLE,
          title: this.$t('eshop.period_type.one_article')
        }
      ]
    }
  }
}
