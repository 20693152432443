export const TOP_PRODUCT_POSITION_TYPE_LEFT = 'left'
export const TOP_PRODUCT_POSITION_TYPE_MIDDLE = 'middle'
export const TOP_PRODUCT_POSITION_TYPE_RIGHT = 'right'

export default {
  methods: {
    prettyPosition: function (id) {
      return this.positionTypeValues.find(value => value.id === id)?.title ?? id
    }
  },
  computed: {
    positionTypeValues () {
      return [
        {
          id: TOP_PRODUCT_POSITION_TYPE_LEFT,
          title: this.$t('eshop.top_product_position_type.left')
        },
        {
          id: TOP_PRODUCT_POSITION_TYPE_MIDDLE,
          title: this.$t('eshop.top_product_position_type.middle')
        },
        {
          id: TOP_PRODUCT_POSITION_TYPE_RIGHT,
          title: this.$t('eshop.top_product_position_type.right')
        }
      ]
    }
  }
}
